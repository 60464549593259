<template>
  <b-modal id="showmodal" v-model="CjkDiv" title="切换成绩库" size="lg" centered title-class="font-18" hide-footer>
    <div class="flexList">
      <el-select class="mr-2" style="width: 20%" placeholder="请选择考试年份" v-model="CjkParam.ksnf" size="small" >
        <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
        </el-option>
      </el-select>
      <input placeholder="关键字" class="h30 form-control border-blue mr-2" v-model="CjkParam.keyWord" maxlength="50"
        style="width: 156px" />
      <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick">
        <i class="iconfont mr-2 icon-mb-search"></i>查询
      </button>
    </div>
    <div class="table-responsive border mt-3">
      <table class="table light-table table-hover">
        <thead class="thead-light">
          <tr>
            <th style="width: 5%">序号</th>
            <th>年份</th>
            <th>成绩库名称</th>
            <th style="width: 8%">选择</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in CjkList" :key="item.sid">
            <td>{{ i + 1 }}</td>
            <td>{{ item.ksnf }}</td>
            <td>
              <a href="javascript:void(0)" @click="changeCjk(item)" class="blue-font">{{ item.ksmc }}</a>
            </td>
            <td>
              <b-button size="sm" variant="info" @click="changeCjk(item)">切换</b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-2 text-right">
      <el-pagination @size-change="handleSizeChange" background small @current-change="handleCurrentChange"
        :current-page.sync="CjkParam.pageNum" :page-size="CjkParam.pageSize" layout="prev, pager, next"
        :total="CjkParam.total">
      </el-pagination>
    </div>
  </b-modal>
</template>

<script>
  import {
    getCjk,
  } from "@/api/score/scoreKu.js"
  export default {
    name: "change-Cjk",
    props: {
      isIndexChange: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        CjkDiv: false,
        CjkParam: {
          ksnf: new Date().getFullYear(),
          keyWord: "",
          pageNum: 1,
          pageSize: 10,
          total: 0
        },
        CjkList: [],
        years: [],
        changCjk: false,
        baseConfig: {},

      }

    },
    methods: {
      getYear() {
        //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: y - i,
              label: y - i,
            });
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: y + x,
                label: y + x,
              });
            }
          }
        }
      },

      searchClick() { //搜索时先将分页的页码归1
        this.CjkParam.pageNum = 1; //当前页
        this.getCjkList() //渲染数据的函数
      },
      // 获取列表
      getCjkList() {
        getCjk(this.CjkParam).then((res) => {
          if (res.status) {
            this.CjkList = res.data;
            this.CjkParam.total = res.total
          }
        });
      },


      // 切换考试
      changeCjk(info) {
        if (!this.isIndexChange) {
          this.setStore("Score", info)
          // window.location.reload()
          if (this.$route.query.sid) {
            window.location.href = "/admin/scoreManage?sid=" + info.sid
          } else {
            window.location.reload()
          }

        } else {
          this.$emit("getScoreStatistics", info.sid)
          this.CjkDiv = false
        }

      },
      handleSizeChange(val) {
        this.CjkParam.pageSize = val
        this.getCjkList()
      },
      handleCurrentChange(val) {
        this.CjkParam.pageNum = val
        this.getCjkList()
      },
    },
    mounted() {
      this.getYear()
      this.getCjkList()
    },
    created() {
      let conf = JSON.parse(window.localStorage.getItem('BaseConfig'))
      this.baseConfig = conf ? conf : {}
      if (this.baseConfig) {
        this.CjkParam.ksnf = this.baseConfig.SystemYear?this.baseConfig.SystemYear:new Date().getFullYear()

      }
    }
  }
</script>

<style scoped>

</style>
